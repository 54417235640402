import React from "react";
import background from './/background.jpg';
import "./styles.css";
export const AboutUs  = () => {
   
    return (
        <div id="backg">'
        <div id="backBG">
            <h1 id="backh" style={{ color: '#DDBC45' }}>ABOUT US</h1>
            {/*<p id="backp" style={{ color: '#DDBC45' }}>At our core, we embody a holistic approach to healthcare, placing a profound emphasis on mental and psychosocial well-being. Our mission revolves around cultivating enduring relationships with the NDIS community, striving to make a significant and positive impact in the lives of those we serve. With a robust foundation of expertise spanning various healthcare sectors, including nursing, aged care. forensic case management, acute rehabilitation, and community nursing, our team's diverse skill set enables us to cater to a wide spectrum of needs. </p>
            <p id="backp" style={{ color: '#DDBC45' }}>Because of our collective experience, we are able to navigate intricate healthcare scenarios with finesse and expertise. What sets us apart is our unwavering commitment to participant and patient-centered care. We steadfastly uphold the principles of safety, quality and individualized attention, ensuring that every interaction is driven by the unique needs and preferences of those under our care.</p>
            <p id="backp" style={{ color: '#DDBC45' }}>Diversity is not just a concept but a cornerstone of our practice. We celebrate and embrace diversity in all its forms, understanding its profound importance in delivering comprehensive and inclusive care. Our approach places paramount importance on respecting patient autonomy, recognizing the significance of empowering individuals to actively participate in decisions regarding their health and well-being.</p>
            <p id="backp" style={{ color: '#DDBC45' }}>Privacy and confidentiality are non-negotiable aspects of our service. We prioritize maintaining the highest standards of privacy, ensuring that each individual's information and journey through our care remains strictly confidential and secure.</p>
            <p id="backp" style={{ color: '#DDBC45' }}>Our brand of care is founded on genuine compassion, a deep-rooted respect for every individual, and an unwavering commitment to providing the highest quality of service. Through our dedication to excellence, we aim not just to meet but to exceed the expectations of those we serve, thereby establishing ourselves as a trusted and reliable beacon of care within the healthcare landscape.</p>
            */}
            <p id="backp" style={{ color: '#DDBC45' }}>We are a healthcare provider driven by a holistic approach, placing profound emphasis on mental and psychosocial well-being. Our mission revolves around building enduring relationships within the NDIS community to create a positive impact in their lives. With expertise in various healthcare sectors, such as nursing, aged care, forensic case management, acute rehabilitation, and community nursing, our diverse team adeptly caters to a broad spectrum of needs.</p>
            <p id="backp" style={{ color: '#DDBC45' }}>What distinguishes us is our unwavering commitment to participant and patient-centered care, prioritizing safety, quality, and individual preferences in every interaction. Diversity is integral to our practice, celebrating its importance in delivering comprehensive and inclusive care. We prioritize patient autonomy and confidentiality, maintaining the highest privacy standards throughout their journey with us. Rooted in genuine compassion and a dedication to excellence, we aim not just to meet but exceed expectations, establishing ourselves as a trusted beacon of top-tier healthcare.</p>
        </div>
        <br></br>
        <br></br>
            
        </div>
       )
}