import { useState } from 'react';
import { Size } from '../../types'
import useWindowSize from '../../hooks/useWindowSize';
import "./styles.css";
import { Drawer, Button } from 'antd';
import { Link } from 'react-router-dom';
import facebook from './facebook.png';
import instagram from './instagram.png';
import linkedin from './linkedin.png';




export const Footnote  = () => {
    const size: Size = useWindowSize();
    

    

    

    
    return size.width >= 900 ? (
        
        
            
            
            <div id="copyright">
            {/*<img src={logo} className="Company-logo" alt="logo" />*/}
            <h4>@2023 by Analogy Care</h4>
            <div id="social">
            {/*<a href={"https://www.facebook.com/Rueseventsdesigns1"} target="_blank" rel="noreferrer">
            <img src={facebook} className="Facebook" alt="logo" />
            </a>
            <a href={"https://www.instagram.com/rueseventsdesigns/"} target="_blank" rel="noreferrer">
            <img src={instagram} className="Instagram" alt="logo" />
            </a>
            <a href={"https://www.instagram.com/rueseventsdesigns/"} target="_blank" rel="noreferrer">
            <img src={linkedin} className="Linkedin" alt="logo" />
    </a>*/}
    <p style={{ color: '#DDBC45' }}><b>Tel:</b> <a style={{ color: '#DDBC45' }} href="tel:+61401932068"><b>+61 401 932 068</b></a> / <a style={{ color: '#DDBC45' }} href="tel:+61450154614"><b>+61 450 154 614</b></a></p>
            </div>
            
            </div>
        
    ) : (
        <div id="copyright">
            
            <h4>@2023 by Analogy Care</h4>
            <div id="social">
             <p style={{ color: '#DDBC45' }}><b>Tel:</b> <a style={{ color: '#DDBC45' }} href="tel:+61401932068"><b>+61 401 932 068</b></a> / <a style={{ color: '#DDBC45' }} href="tel:+61450154614"><b>+61 450 154 614</b></a></p>
             </div>
        </div>   
    )
}