import React, { useEffect } from "react";
import "./styles.css";
import image1 from './/1.jpg';
import image2 from './/2.jpg';
import image3 from './/3.jpg';
import image4 from './/4.jpg';
import useWindowSize from "../../hooks/useWindowSize";
import { Size } from "../../types";


type Image = {
    url: string;
    caption: string;
  };

  
  
  // Sample image data
  const images: Image[] = [
    {
      url: image1,
      caption: 'Support services/ Personal care',
      
    },
    {
      url: image2,
      caption: 'Transport/Travel',
    },
    {
      url: image3,
      caption: 'Accomodation/Shared living',
    },
    {
      url: image4,
      caption: 'Community nursing',
    },
  ];

  type Content = {
    heading: string;
    paragraph: string;
    listItems: string[];
  };

  
  const content1: Content[] = [
    {
      heading: 'Support services/ Personal care',
      paragraph: 'Our support services include: ',
      listItems: ['Mental health recovery coaching', 'Community participation', 'Household tasks', 'Meal time management', 'Life stage transition'],
    },
    {
      heading: 'Community nursing',
      paragraph: 'Our community nursing services include:',
      listItems: ['Medication management', 'Complex bowel management', 'Urinary catheter management', 'Complex wound management', 'Basic ventilator management', 'Severe dysphagia management' ],
    },
  ];

export const Service = () => {
    const numImagesPerRow = 4; // Number of images to display in a row
    const imageWidth = `${100 / numImagesPerRow}%`;
    const size: Size = useWindowSize();
    useEffect(() => {
        document.title = "Analogy Care - Our Services"
      }, [])
   
    return size.width >= 900 ? (
        <div id="backservice">'
        <div id="backSV">
            <h1 id="backheadserv" style={{ color: '#DDBC45' }}>SERVICES WE PROVIDE</h1>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {images.map((image, index) => (
        <div key={index} style={{ flex: `0 0 ${imageWidth}`, textAlign: 'center' }}>
          <img src={image.url} alt={`Image ${index + 1}`} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
          <b><p style={{ fontFamily: 'Inika', fontSize: '20px', color: '#DDBC45' }}>{image.caption}</p></b>
        </div>
      ))}
    </div>
    
    
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', padding: '20px' }}>
      {content1.map((item, index) => (
        <div key={index} style={{ flex: 1, textAlign: 'center' }}>
          <h2 style={{ fontFamily: 'Inika', fontSize: '24px', marginBottom: '8px', color: '#DDBC45' }}>
            {item.heading}
          </h2>
          <p style={{ fontFamily: 'Inika', fontSize: '16px', color: '#DDBC45' }}>{item.paragraph}</p>
          <ul style={{ fontFamily: 'Inika', fontSize: '16px',  marginTop: '8px', listStyle: 'none', color: '#DDBC45' }}>
            {item.listItems.map((listItem, idx) => (
              <li key={idx}>{listItem}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
        </div>
        
       ) : (
        <div id="backSV">
            <h1 id="backheadserv" style={{ color: '#DDBC45' }}>SERVICES WE PROVIDE</h1>
        <div className="columns">
      <div className="column">
        <img src={image1} alt="Image 1" />
        <p>Support services/Personal care</p>
      </div>
      <div className="column">
        <img src={image2} alt="Image 2" />
        <p>Transport/Travel</p>
      </div>
      <div className="column">
        <img src={image3} alt="Image 3" />
        <p>Accomodation/ Shared living</p>
      </div>
      <div className="column">
        <img src={image4} alt="Image 4" />
        <p>Community nursing</p>
      </div>
    </div>
    <div className="rows">
      <div className="row">
        <h2>Support Services/Personal care</h2>
        <p>Our support services include:</p>
        <ul>
          <li>Mental health recovery coaching</li>
          <li>Community participation</li>
          <li>Household tasks</li>
          <li>Meal time management</li>
          <li>Life stage transition</li>
        </ul>
      </div>
      <div className="row">
        <h2>Community nursing</h2>
        <p>Our community nursing include:</p>
        <ul>
          <li>Medication management</li>
          <li>Complex bowel management</li>
          <li>Urinary catheter management</li>
          <li>Complex wound management</li>
          <li>Basic ventilator management</li>
          <li>Severe dysphagia management</li>
        </ul>
      </div>
    </div>
    </div>
    
       )
}