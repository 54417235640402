import React, { useEffect } from "react";
import "./styles.css";
import logo from './/logotrans.png';
import background from './/background.jpg';
import useWindowSize from "../../hooks/useWindowSize";
import { Size } from "../../types";
export const Background  = () => {
    const size: Size = useWindowSize();
    useEffect(() => {
        document.title = "Analogy Care - Home"
      }, [])
    return size.width >= 900 ?(
        <div id="bg">
            <img src={logo} className="Company-logo" alt="logo" />
        </div>
       ) : (
        <div id="bg">
            <img src={background} className="background" alt="logo" />
        </div>
       )
}