import { useState } from 'react';
import { Size } from '../../types'
import useWindowSize from '../../hooks/useWindowSize';
import logo from './/logotrans.png';
import "./styles.css";
import { Drawer, Button } from 'antd';
import { Link, NavLink } from 'react-router-dom';





export const ContactUs  = () => {
    const size: Size = useWindowSize();
    

    

    

    
    return size.width >= 900 ? (
        
        <div id="contactus">
            <NavLink to="/contact"><Button id="button"><b>CONTACT US</b></Button></NavLink>
        </div>



        
    ) : (
        
        <div id="contactus">
            <NavLink to="/contact"><Button id="button"><b>CONTACT US</b></Button></NavLink>
        </div>
        
    )
}