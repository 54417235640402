import './App.css';
import { Home } from './components/home';
import { Contact } from './components/contact';
import { About } from './components/about';
import { Services } from './components/services';
import {Routes, Route } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Loading } from './components/loading';



export default function App() {
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    // Simulate loading for 2 seconds
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  
  return (
      <div className="App">
        {isLoading ? (
        <Loading />
       ) : (
        <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/services" element={<Services/>} />
    </Routes>
       )}
           
  
        
    </div>
  );
  
}
