import { useState } from 'react';
import { Size } from '../../types'
import useWindowSize from '../../hooks/useWindowSize';
import "./styles.css";





export const WhatWeDo  = () => {
    const size: Size = useWindowSize();

    return size.width >= 900 ? (
        
        <div id="whatwedo">
            <h1>WHAT WE DO</h1>
            <p>We provide genuine care in our services, allowing the client to be autonomous in their care whilst respectfully tending to their needs. We aim to cultivate long standing relationships with our clients and the NDIS community. Our team has extensive experience in nursing, mental health and aged care and provides safe quality services. We provide guaranteed privacy in our care for clients and in the way we handle their personal care.</p>
            <p>Some of the services we  provide include:
            <ul style={{ backgroundColor: '#484646' }}>
                <li>Community nursing</li>
                <li>Personal Care/Support Services</li>
                <li>Mental health recovery coaching</li>
                <li>Transport</li>
                <li>Accomodation</li>
            </ul>
            <br></br>
            </p>
            
        
        </div>
      
    ) : (
        <div id="whatwedo">
        <h1>WHAT WE DO</h1>
        <p>We provide genuine care in our services, allowing the client to be autonomous in their care whilst respectfully tending to their needs. We aim to cultivate long standing relationships with our clients and the NDIS community. Our team has extensive experience in nursing, mental health and aged care and provides safe quality services. We provide guaranteed privacy in our care for clients and in the way we handle their personal care.</p>
        <p>Some of the services we  provide include:
        <ul style={{ backgroundColor: '#484646' }}>
            <li>Community nursing</li>
            <li>Personal Care/Support Services</li>
            <li>Mental health recovery coaching</li>
            <li>Transport</li>
            <li>Accomodation</li>
        </ul>
        <br></br>
        </p>
        
    
    </div>
        
    )
}