import { useState } from 'react';
import { Size } from '../../types'
import useWindowSize from '../../hooks/useWindowSize';
import logo from './/logotrans.png';
import "./styles.css";
import { Drawer, Button } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import menu from './/menu.png';
import type { MenuProps } from 'antd';
import { Footnote } from '../footnote';

type NavProps = {
    home: boolean
    about: boolean
    services: boolean
    contact: boolean
}


export const NavigationBar  = (props: NavProps) => {

   
    const size: Size = useWindowSize();
    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    const items: MenuProps['items'] = [
        {
          key: '1',
          type: 'group',
          
          children: [
            {
              key: '1-1',
              label: 'Support Services/ Personal Care',
              children: [
                {
                    key: '1-1',
                    label: 'Community participation',
                },
                {
                    key: '1-2',
                    label: 'Household tasks',
                },
                {
                    key: '1-3',
                    label: 'Meal time management',
                },
                {
                    key: '1-4',
                    label: 'Life stage transition',
                },
                {
                    key: '1-5',
                    label: 'Mental health recovery coaching',
                }
              ]
            },
            {
              key: '1-2',
              label: 'Travel/Transport',
              children: []
            },
            {
                key: '1-3',
                label: 'Accomodation',
                children: [
                    {
                        key: '1-1',
                        label: 'Shared living',
                    }
                ]
            },
            {
                key: '1-4',
                label: 'Community Nursing',
                children: [
                    {
                        key: '1-1',
                        label: 'Medication management',
                    },
                    {
                        key: '1-2',
                        label: 'Complex bowel management',
                    },
                    {
                        key: '1-3',
                        label: 'Urinary catheter management',
                    },
                    {
                        key: '1-4',
                        label: 'Complex wound management',
                    },
                    {
                        key: '1-5',
                        label: 'Basic ventilator management',
                    },
                    {
                        key: '1-6',
                        label: 'Severe dysphagia management',
                    }
                ]
            },
          ],
        },

        
      ];
    

    

    
    return size.width >= 900 ? (
        
        <div id="header-container">
            <div id="logo">
            <img src={logo} className="Company-logo" alt="logo" />
            </div>
            {props.home ? (
                <><NavLink to="/">
                    <h3 id="home-active" style={{ color: '#DDBC45' }}><b>HOME</b></h3>
                </NavLink><NavLink to="/about">
                        <h3 id="about" style={{ color: '#DDBC45' }}><b>ABOUT US</b></h3>
                    </NavLink><NavLink to="/services">
                       {/*} <Dropdown menu={{ items }}>
                            <a onClick={(e) => e.preventDefault()}>
            <Space>*/}
                            <NavLink to="/services"><h3 id="services" style={{ color: '#DDBC45' }}><b>SERVICES</b></h3></NavLink>
                               {/*} <DownOutlined color='#DDBC45'/>*/}
                                
                           {/*} </Space>
                            </a>
        </Dropdown>*/}
                    </NavLink>
                    <NavLink to="/contact">
                        <h3 id="contact" style={{ color: '#DDBC45' }}><b>CONTACT US</b></h3>
                    </NavLink><div id="socials">
                    </div></>
            ) : props.about ? (
                <><NavLink to="/">
                    <h3 id="home" style={{ color: '#DDBC45' }}><b>HOME</b></h3>
                </NavLink><NavLink to="/about">
                        <h3 id="about-active" style={{ color: '#DDBC45' }}><b>ABOUT US</b></h3>
                    </NavLink><NavLink to="/services">
                        {/*<Dropdown menu={{ items }}>
                            <a onClick={(e) => e.preventDefault()}>
            <Space>*/}
                            <NavLink to="/services"><h3 id="services" style={{ color: '#DDBC45' }}><b>SERVICES</b></h3></NavLink>
                                {/*<DownOutlined color='#DDBC45'/>
                                
                            </Space>
                            </a>
        </Dropdown>*/}
                    </NavLink><NavLink to="/contact">
                        <h3 id="contact" style={{ color: '#DDBC45' }}><b>CONTACT US</b></h3>
                    </NavLink><div id="socials">
                    </div></>
            ) : props.services ? (
                <><NavLink to="/">
                    <h3 id="home" style={{ color: '#DDBC45' }}><b>HOME</b></h3>
                </NavLink><NavLink to="/about">
                        <h3 id="about" style={{ color: '#DDBC45' }}><b>ABOUT US</b></h3>
                    </NavLink><NavLink to="/services">
                        {/*<Dropdown menu={{ items }}>
                            <a onClick={(e) => e.preventDefault()}>
            <Space>*/}
                            <NavLink to="/services"><h3 id="services-active" style={{ color: '#DDBC45' }}><b>SERVICES</b></h3></NavLink>
                               {/* <DownOutlined color='#DDBC45'/>
                                
                            </Space>
                            </a>
        </Dropdown>*/}
                    </NavLink><NavLink to="/contact">
                        <h3 id="contact" style={{ color: '#DDBC45' }}><b>CONTACT US</b></h3>
                    </NavLink><div id="socials">
                    </div></>
            ) : props.contact ? (
                <><NavLink to="/">
                    <h3 id="home" style={{ color: '#DDBC45' }}><b>HOME</b></h3>
                </NavLink><NavLink to="/about">
                        <h3 id="about" style={{ color: '#DDBC45' }}><b>ABOUT US</b></h3>
                    </NavLink><NavLink to="/services">
                        {/*<Dropdown menu={{ items }}>
                            <a onClick={(e) => e.preventDefault()}>
            <Space>*/}
                            <NavLink to="/services"><h3 id="services" style={{ color: '#DDBC45' }}><b>SERVICES</b></h3></NavLink>
                                {/*<DownOutlined color='#DDBC45'/>
                                
                            </Space>
                            </a>
        </Dropdown>*/}
                    </NavLink><NavLink to="/contact">
                        <h3 id="contact-active" style={{ color: '#DDBC45' }}><b>CONTACT US</b></h3>
                    </NavLink><div id="socials">
                    </div></>
            ) : (
                <><NavLink to="/">
                    <h3 id="home" style={{ color: '#DDBC45' }}><b>HOME</b></h3>
                </NavLink><NavLink to="/about">
                        <h3 id="about" style={{ color: '#DDBC45' }}><b>ABOUT US</b></h3>
                    </NavLink><NavLink to="/services">
                        <h3 id="services" style={{ color: '#DDBC45' }}><b>SERVICES</b></h3>
                    </NavLink><NavLink to="/contact">
                        <h3 id="contact" style={{ color: '#DDBC45' }}><b>CONTACT US</b></h3>
                    </NavLink><div id="socials">
                    </div></>
            )};       
        </div>



        
    ) : (
        <div id="header-container">
            
            <Button className="menu" onClick={showDrawer}>
            <img className="menu-img"src={menu}  alt="menu" />
            </Button>
            <Link to="/">
                <img className="logo-img"src={logo}  alt="logo" />
            </Link>
            <div id="cont">
                {props.home ? (
                    <Drawer title={<img src={logo} className="Company-logo" alt="logo" />} placement="left" onClose={onClose} visible={visible} headerStyle={{ backgroundColor: "#484646" }}
                        bodyStyle={{ backgroundColor: "#484646" }} footer="@2023 by Analogy Care" footerStyle={{color: "#DDBC45", backgroundColor: "#D9D9D9", fontFamily: "Inika"}}>
                        <NavLink to="/">
                            <h3 id="home-active" style={{ color: '#DDBC45' }}><b>HOME</b></h3>
                        </NavLink>
                        <NavLink to="/about">
                            <h3 id="about" style={{ color: '#DDBC45' }}><b>ABOUT US</b></h3>
                        </NavLink>
                        <NavLink to="/services">
                            <h3 id="services" style={{ color: '#DDBC45' }}><b>SERVICES</b></h3>
                        </NavLink>
                        <NavLink to="/contact">
                            <h3 id="contact" style={{ color: '#DDBC45' }}><b>CONTACT US</b></h3>
                        </NavLink>
                    </Drawer>
                ) : props.about ? (
                    <Drawer title={<img src={logo} className="Company-logo" alt="logo" />} placement="left" onClose={onClose} visible={visible} headerStyle={{ backgroundColor: "#484646" }}
                        bodyStyle={{ backgroundColor: "#484646" }} footer="@2023 by Analogy Care" footerStyle={{color: "#DDBC45", backgroundColor: "#D9D9D9", fontFamily: "Inika"}}>
                        <NavLink to="/">
                            <h3 id="home" style={{ color: '#DDBC45' }}><b>HOME</b></h3>
                        </NavLink>
                        <NavLink to="/about">
                            <h3 id="about-active" style={{ color: '#DDBC45' }}><b>ABOUT US</b></h3>
                        </NavLink>
                        <NavLink to="/services">
                            <h3 id="services" style={{ color: '#DDBC45' }}><b>SERVICES</b></h3>
                        </NavLink>
                        <NavLink to="/contact">
                            <h3 id="contact" style={{ color: '#DDBC45' }}><b>CONTACT US</b></h3>
                        </NavLink>
                    </Drawer>
                ) : props.services ? (
                    <Drawer title={<img src={logo} className="Company-logo" alt="logo" />} placement="left" onClose={onClose} visible={visible} headerStyle={{ backgroundColor: "#484646" }}
                        bodyStyle={{ backgroundColor: "#484646" }} footer="@2023 by Analogy Care" footerStyle={{color: "#DDBC45", backgroundColor: "#D9D9D9", fontFamily: "Inika"}}>
                        <NavLink to="/">
                            <h3 id="home" style={{ color: '#DDBC45' }}><b>HOME</b></h3>
                        </NavLink>
                        <NavLink to="/about">
                            <h3 id="about" style={{ color: '#DDBC45' }}><b>ABOUT US</b></h3>
                        </NavLink>
                        <NavLink to="/services">
                            <h3 id="services-active" style={{ color: '#DDBC45' }}><b>SERVICES</b></h3>
                        </NavLink>
                        <NavLink to="/contact">
                            <h3 id="contact" style={{ color: '#DDBC45' }}><b>CONTACT US</b></h3>
                        </NavLink>
                    </Drawer>
                ) : props.contact ? (
                    <Drawer title={<img src={logo} className="Company-logo" alt="logo" />} placement="left" onClose={onClose} visible={visible} headerStyle={{ backgroundColor: "#484646" }}
                        bodyStyle={{ backgroundColor: "#484646" }} footer="@2023 by Analogy Care" footerStyle={{color: "#DDBC45", backgroundColor: "#D9D9D9", fontFamily: "Inika"}}>
                        <NavLink to="/">
                            <h3 id="home" style={{ color: '#DDBC45' }}><b>HOME</b></h3>
                        </NavLink>
                        <NavLink to="/about">
                            <h3 id="about" style={{ color: '#DDBC45' }}><b>ABOUT US</b></h3>
                        </NavLink>
                        <NavLink to="/services">
                            <h3 id="services-active" style={{ color: '#DDBC45' }}><b>SERVICES</b></h3>
                        </NavLink>
                        <NavLink to="/contact">
                            <h3 id="contact" style={{ color: '#DDBC45' }}><b>CONTACT US</b></h3>
                        </NavLink>
                    </Drawer>
                ) : (
                    <Drawer title={<img src={logo} className="Company-logo" alt="logo" />} placement="left" onClose={onClose} visible={visible} headerStyle={{ backgroundColor: "#484646" }}
                    bodyStyle={{ backgroundColor: "#484646" }} footer="@2023 by Analogy Care" footerStyle={{color: "#DDBC45", backgroundColor: "#D9D9D9", fontFamily: "Inika"}}>
                    <NavLink to="/">
                        <h3 id="home" style={{ color: '#DDBC45' }}><b>HOME</b></h3>
                    </NavLink>
                    <NavLink to="/about">
                        <h3 id="about" style={{ color: '#DDBC45' }}><b>ABOUT US</b></h3>
                    </NavLink>
                    <NavLink to="/services">
                        <h3 id="services" style={{ color: '#DDBC45' }}><b>SERVICES</b></h3>
                    </NavLink>
                    <NavLink to="/contact">
                        <h3 id="contact" style={{ color: '#DDBC45' }}><b>CONTACT US</b></h3>
                    </NavLink>
    
                    {/*<div id="socials">
                    <a href={"https://www.facebook.com/Rueseventsdesigns1"} target="_blank" rel="noreferrer">
                    <img src={facebook} className="Facebook-Link" alt="logo" />
                    </a>
                    <a href={"https://www.instagram.com/rueseventsdesigns/"} target="_blank" rel="noreferrer">
                    <img src={instagram} className="Instagram-Link" alt="logo" />
                    </a>
                    </div>*/}
                    <Footnote></Footnote>
                    </Drawer>
                )}
            </div>
       
        </div>
        
        
    )
}