import { useEffect } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { Size } from "../../types";
import { ContactForm } from "../contactform";
import { NavigationBar } from "../navigationBar";
import { Footnote } from "../footnote";
import "./styles.css";

export const Contact  = () => {
    const size: Size = useWindowSize();
    useEffect(() => {
        document.title = "Analogy Care - Contact Us"
      }, [])

    return /*size.width >= 900 ?*/ (
        <div id="home-container">
            <NavigationBar home={false} about={false} services={false} contact={true}></NavigationBar>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <ContactForm></ContactForm> 
            
            
            <div id="copyrights">
            {/*<img src={logo} className="Company-logo" alt="logo" />*/}
            <h4>@2023 by Analogy Care</h4>
            <div id="social">
            {/*<a href={"https://www.facebook.com/Rueseventsdesigns1"} target="_blank" rel="noreferrer">
            <img src={facebook} className="Facebook" alt="logo" />
            </a>
            <a href={"https://www.instagram.com/rueseventsdesigns/"} target="_blank" rel="noreferrer">
            <img src={instagram} className="Instagram" alt="logo" />
            </a>
            <a href={"https://www.instagram.com/rueseventsdesigns/"} target="_blank" rel="noreferrer">
            <img src={linkedin} className="Linkedin" alt="logo" />
    </a>*/}
        <p style={{ color: '#DDBC45' }}><b>Tel:</b> <a style={{ color: '#DDBC45' }} href="tel:+61401932068"><b>+61 401 932 068</b></a> / <a style={{ color: '#DDBC45' }} href="tel:+61450154614"><b>+61 450 154 614</b></a></p>

            </div>
            
            </div>
            
           
            
        </div>
        
    /*) : (
        <div id="home-container">
        
       
        
    </div>*/
        
    )
}