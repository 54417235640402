import { Size } from '../../types'
import useWindowSize from '../../hooks/useWindowSize';
import "./styles.css";
//import 'antd/dist/antd.min.css';
import { useEffect } from 'react'
import { NavigationBar } from '../navigationBar';
import { Background } from '../background';
import { Footnote } from '../footnote';
import { ContactUs } from '../contactus';
import { WhatWeDo } from '../whatwedo';




export const Home  = () => {
    const size: Size = useWindowSize();
    useEffect(() => {
        document.title = "Analogy Care - Home"
      }, [])

    return size.width >= 900 ? (
        <div id="home-container">
            <NavigationBar home={true} about={false} services={false} contact={false}></NavigationBar>
            <Background></Background>
            <WhatWeDo></WhatWeDo>
            <ContactUs></ContactUs>
            <Footnote></Footnote>
            
           
            
        </div>
        
    ) : (
        <div id="home-container">
        <NavigationBar home={true} about={false} services={false} contact={false}></NavigationBar>
        <br></br>
        <br></br>
        <br></br>
        <Background></Background>
        <WhatWeDo></WhatWeDo>
        <ContactUs></ContactUs>
        <Footnote></Footnote>
        
    </div>
        
    )
}