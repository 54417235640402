import { useEffect } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { Size } from "../../types";
import { NavigationBar } from "../navigationBar";
import { Service } from "../service";
import { Footnote } from "../footnote";
import "./styles.css";


export const Services  = () => {
    const size: Size = useWindowSize();
    useEffect(() => {
        document.title = "Analogy Care - Our Services"
      }, [])

    return size.width >= 900 ? (
        <div id="home-container">
            <NavigationBar home={false} about={false} services={true} contact={false}></NavigationBar>
            <Service></Service>
            <Footnote></Footnote>  
           
            
        </div>
        
    ) : (
        <div id="home-container">
            <NavigationBar home={false} about={false} services={true} contact={false}></NavigationBar>
            <br></br>
            <br></br>
            <br></br>
            <Service></Service>
            <Footnote></Footnote> 
       
        
    </div>
        
    )
}